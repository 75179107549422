import React from 'react'
import { StaticImage as Img } from 'gatsby-plugin-image';
import Content from './style'
import { Link, SuperTag } from '~components';
import { Col, Container, Row } from 'react-bootstrap';
import img from "../../../assets/image/project-management/02-MonetizeConnections@3x.svg";
import { FaLocationArrow } from "react-icons/fa6";


export default function ContentSectionTwo({id}){

return(
<Content id={id}>
  
  <Container>
    <Content.Block>
    <Row className="align-items-center justify-content-center justify-content-lg-start">
    <Col className="col-xl-6 col-lg-6 col-md-8 col-xs-10">
        <Content.Image>
            <img className="w-100" src={img} alt="content" layout="fullWidth" placeholder="blurred" />
        </Content.Image>
      </Col>
      <Col xs="12" className="col-xl-6 col-lg-6 col-md-8 col-xs-10">
      <Content.Box>
        <Content.Title as="h2"> <SuperTag value="Expand Your Network"/> </Content.Title>
        
        <div className='mb-5'>
        <ol class="fa-ul">
        <li><span class="fa-li"><FaLocationArrow /></span><Content.Text>Streamline the process of connecting with industry professionals and potential clients.</Content.Text></li>
        <li><span class="fa-li"><FaLocationArrow /></span><Content.Text>Efficiently target and engage with a larger audience through personalized outreach strategies, without sacrificing quality.</Content.Text></li>
        <li><span class="fa-li"><FaLocationArrow /></span><Content.Text>Foster professional relationships and identify new opportunities, while saving time.</Content.Text></li>
        </ol>
        </div>
        
        <Content.Button  className="btn btn-primary text-white" target="_blank" to="//account.we-connect.io/signup">Uncover hidden opportunities in your network</Content.Button>
        
        </Content.Box>
      </Col>
    </Row>
    </Content.Block>
  </Container>
</Content>
)
}