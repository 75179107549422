import React from 'react'
//import { StaticImage as Img } from 'gatsby-plugin-image';
import Content from './style'
import { SuperTag } from '~components';
import { Col, Container, Row } from 'react-bootstrap';
import img from "../../../assets/image/project-management/01-GetFreshLeads@3x.svg";
import { FaLocationArrow } from "react-icons/fa6";

export default function ContentSectionOne({id}){

return(
   
<Content backgroundColor="#F3F4F6" id={id}>
  
  <Container>
    <Content.Inner>
    <Row className="align-items-center justify-content-center justify-content-lg-start">
      <Content.TextBlock>
      </Content.TextBlock>
      <Col className="col-xl-6 col-lg-6 col-md-8 col-xs-10 order-2 order-lg-1">
      <Content.Box>
        <Content.Title as="h2"> <SuperTag value="Target the Right People"/> </Content.Title>
        
        <div className='mb-5'>
        <ol class="fa-ul">
        <li><span class="fa-li"><FaLocationArrow /></span><Content.Text className='pl-2'>Leverage LinkedIn’s extensive network for precise targeting to connect with the right people.</Content.Text></li>
        <li><span class="fa-li"><FaLocationArrow /></span><Content.Text>Identify key decision-makers in your industry and build your reputation.</Content.Text></li>
        <li><span class="fa-li"><FaLocationArrow /></span><Content.Text>Send campaigns to members of relevant LinkedIn groups or target those who liked a particular post, bypassing the weekly invite limit.</Content.Text></li>
        </ol>
        </div>
        
        <Content.Button  className="btn btn-primary text-white" target="_blank" to="//account.we-connect.io/signup">Start engaging your audience</Content.Button>
        
        </Content.Box>
      </Col>
      <Col xs="12" className="col-xl-6 col-lg-6 col-md-8 col-xs-10 order-1 order-lg-2">
        <Content.Image> 
        <img className="w-100" src={img} alt="content" layout="fullWidth" placeholder="blurred" />
        </Content.Image>
      </Col>
    </Row>
    </Content.Inner>
  </Container>
</Content>
)
}